
function parseWidth(width) {
  return parseInt(width.replace(/[<>]/, '').trim());
}

function openSlideout(slideOutEl) {
  slideOutEl.classList.add('opened');
  if (window.innerWidth < 768) {
    document.body.classList.add('no-scroll');
  }
  const slideOutElSelect = slideOutEl.querySelector('select');
  if (slideOutElSelect) {
    slideOutElSelect.focus();
    slideOutElSelect.click();
    slideOutElSelect.scrollIntoView({ behavior: 'smooth' });
  }
}
window.addEventListener('open-slideOut', (e) => {
  const name = e.detail.slideOutName;
  const slideOut = document.querySelector(`[data-slide-out-name="${name}"]`);
  if (slideOut) {
    openSlideout(slideOut)
  }
});
document.querySelectorAll('[data-slide-out-name]').forEach(slideOut => {
  // set up close button
  slideOut.querySelector('[data-close]').addEventListener('click', () => {
    slideOut.classList.remove('opened');
    document.body.classList.remove('no-scroll');
  });
  // set up activator
  const name = slideOut.dataset.slideOutName;
  document.querySelectorAll(`[data-slide-out-activator="${name}"]`).forEach(activatorEl => {
    activatorEl.addEventListener('click', () => {
      // check if there is a conditional width, if so we only open the slide out based on the condition
      const conditionalWidth = activatorEl.dataset.slideOutActivatorOnly;
      if (typeof conditionalWidth !== 'undefined') {
        const width = parseWidth(conditionalWidth);
        if (conditionalWidth.includes('<')) {
          if (window.innerWidth < width) {
            openSlideout(slideOut);
          }
        } else {
          if (window.innerWidth > width) {
            openSlideout(slideOut);
          }
        }
      } else {
        // No conditional width, open it regardless
        openSlideout(slideOut);
      }
    })
  })
});

export { openSlideout };

